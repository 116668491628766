<template>
  <div class="reset_profile_Password_wrapper">
    <p class="title">{{ $t('resetPassword.header') }}</p>
    <div class="resetPassword_box" v-show="isAuthorized">
      <div class="form_box" v-show="formFlag">
        <el-form label-position="top" :model="ruleResetPasswordForm" :rules="rulesResetPassword"
          ref="ruleResetPasswordForm">
          <el-form-item prop="pass">
            <div class="self_label">{{ $t('changePw.field.newPw') }}</div>
            <el-input type="password" v-model="ruleResetPasswordForm.pass" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item prop="checkPass">
            <div class="self_label">{{ $t('changePw.field.confirmPw') }}</div>
            <el-input type="password" v-model="ruleResetPasswordForm.checkPass" autocomplete="off"></el-input>
          </el-form-item>
          <el-button class="reset_btn" @click.native="submitForm('ruleResetPasswordForm')" data-testid="resetPwd">
            {{ $t('common.button.submit') }}
          </el-button>
        </el-form>
      </div>
      <div class="reset_success" v-show="successFlag">
        <p>{{ $t('resetPassword.succ2') }}</p>
        <el-button type="primary" @click="goLogin" class="go_login_btn">
          {{ $t('common.button.login') }}
        </el-button>
      </div>
    </div>
    <p class="no_authroization" v-show="isNotAuthroized">{{ $t('resetPassword.failed') }}</p>
  </div>
</template>

<script>
import { md5 } from '@/util/encrypt';
import { apiCheckResetPasswordAuthorization, apiSubmitResetProfilePassword } from '@/resource';
import passwordCheckMixin from '@/mixins/passwordCheck';
// import { validatePass } from '@/util/validation';

export default {
  mixins: [passwordCheckMixin],
  data() {
    // const validatePass2 = (rule, value, callback) => {
    //   if (value === '') {
    //     callback(new Error(this.$t('common.formValidation.newPwReq')));
    //   } else if (value !== this.ruleResetPasswordForm.pass) {
    //     this.isError = true;
    //     callback(new Error(this.$t('common.formValidation.confirmNotMatch')));
    //   } else {
    //     this.isError = false;
    //     callback();
    //   }
    // };
    return {
      isAuthorized: false,
      isNotAuthroized: true,
      formFlag: true,
      successFlag: false,
      // isError: true,
      ruleResetPasswordForm: {
        pass: '',
        checkPass: '',
        token: ''
      },
      // rulesResetPassword: {
      //   pass: '',
      //   checkPass: '',
      // }
    };
  },
  components: {},
  mounted() {
    const token = this.$route.query.token;
    this.verifyAuthorization(token);
  },
  methods: {
    verifyAuthorization(token) {
      apiCheckResetPasswordAuthorization({
        token: this.$route.query.token
      }).then(resp => {
        if (resp.data.code == 0) {
          this.ruleResetPasswordForm.token = resp.data.data;
          this.isAuthorized = true;
          this.isNotAuthroized = !this.isAuthorized;
        } else {
          this.isAuthorized = false;
          this.$router.push('/login');
        }
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          apiSubmitResetProfilePassword({
            token: this.ruleResetPasswordForm.token,
            pass: md5(this.ruleResetPasswordForm.pass),
            checkPass: md5(this.ruleResetPasswordForm.checkPass)
          }).then(resp => {
            if (resp.data.code == 0 && resp.data.data.result == true) {
              this.formFlag = false;
              this.successFlag = true;
            }
          });
        } else {
          return false;
        }
      });
    },
    goLogin() {
      this.$router.push('/login')
    }
  }
};
</script>

<style lang="scss" scoped>
// @import '@/assets/css/pages/resetProfilePasswordPug.scss';
.reset_profile_Password_wrapper {
  p {
    font-size: 14px;
    color: $text-secondary;
    line-height: 21px;
  }

  .title {
    font-size: 24px;
    color: #09090D;
    line-height: 36px;
    font-weight: 700;
    text-align: center;
  }

  .no_authroization,
  .reset_success {
    text-align: center;
    margin-top: 24px;
  }

  .reset_success {
    display: flex;
    flex-direction: column;
    align-items: center;

    .go_login_btn {
      margin-top: 24px;
    }
  }

  .el-form-item:nth-child(1) {
    margin-top: 24px;
  }

  .self_label {
    font-size: 16px;
    color: $text-primary;
    line-height: 24px;
    font-weight: 700;
    margin-bottom: 8px;
    @include rtl-sass-prop-dual(text-align, left, text-align, right);

    span {
      font-weight: 400;
      color: $danger;
      @include rtl-sass-prop(margin-right, margin-left, 2px);
    }
  }

  .reset_btn {
    width: 100%;
    height: 40px;
    font-size: 14px;
    font-weight: 700;
    color: $white;
    border-radius: 8px;
    background: $secondary;
    margin-top: 16px;
  }

}
</style>
